.fram-details h1 {
  font-size: 22px;
  padding: 10px 0px 0px 0px;
}
img.portfolio {
  width: 100%;
  cursor: pointer;
}
h3.pro {
  color: #f4b946;
}
button.btn66 {
  border: none;
  color: #000;
  border-radius: 40px 40px 40px 20px;
  cursor: pointer;
  display: inline-block;
  padding: 14px 16px;
  background: rgb(var(--primary-1));
  margin: 0px 0px 0px 5px;
}
button.btn65{
  border: none;
  margin: 0px 0px 0px 5px;
  color: #000;
  border-radius: 40px 40px 40px 20px;
  cursor: pointer;
  display: inline-block;
  padding: 14px 16px;
  background: rgb(30, 229, 121);
}

.featuredImages-desktop{
  display: block;
}
.featuredImages-mobile{
  display: none;
}

@media(max-width:767px){
  .featuredImages-desktop{
    display: none;
  }
  .featuredImages-mobile{
    display: block;
  }
  
}


@media (max-width:480px){
  .fram-details h1 {
    font-size: 19px;
    padding: 10px 0px 0px 0px;
    margin-bottom: 0px;
}
}