.service-reason-card {
    position: relative;
    background: rgb(var(--bg-2));
    transition: var(--transition);
    height: 100%;
    width: 100%;

}

.service-reason-card .card-icon {
    width: 55px;
    min-width: 55px;
    max-width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(var(--primary-1));
    color: rgb(var(--n700));
    transition: var(--transition);
}

.service-reason-card .card-icon i {
    font-size: 33px;
}



.service-reason-card:hover {
    cursor: pointer;
    background: rgb(var(--primary-1));
    color: #000;
}

.service-reason-card :hover .service-content-area h3.title.fw-bold.mb-2,
.service-reason-card:hover .description {
    color: rgb(var(--n700));
}

.service-reason-card .card-icon:hover .card-icon {
    background: rgb(var(--n700));
    color: rgb(var(--primary-1));
}

.service-content-area h3 {
    font-size: 23px;
    font-weight: 500 !important;
}

.service-hd ul li {
    padding: 2px 0px;
    border-left: 2px solid #ffc107;
    margin-bottom: 21px;
    height: 34px;
}

.service-hd ul li span {
    padding-left: 10px;
}

.service-hd ul {
    padding: 30px 0px 0px 0px;
    margin-bottom: 0px;
}

.services-t21-text {
    text-align: center;
}

.diffrent p {
    font-size: 18px;
    line-height: 31px;
}

h6.service-offer {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 400;
}

@media(max-width:991px) {
    .service-reason-card {
        height: auto;
        margin-bottom: 25px;
    }
}

@media(max-width:767px) {
    .service-reason-card {
        height: 330px;
        margin-bottom: 25px;
    }
}

@media(max-width:575px) {
    .service-reason-card {
        height: auto;
        margin-bottom: 25px;
    }

    h6.service-offer {
        font-size: 24px;
        margin-bottom: 6px;
    }

    .same-service {
        margin-top: 0px !important;
    }
}

@media(max-width:375px) {
    .diffrent p {
        font-size: 16px;
        line-height: 23px;
    }

    h6.service-offer {
        font-size: 21px;
        margin-bottom: 6px;
    }

    .service-reason-card {
        height: auto;
        margin-bottom: 10px;

    }
}