
.industry-workfor {
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    margin-top: 10px;
    padding: 15px 18px;
    background-color: rgb(51 51 52);
}
.industry-workfor img {
    opacity: 100%;
    width: 40px;
}
.industry-workfor h6 {
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 7px;
}
.icon-btn-2.fill {
    border: unset;
    color: rgb(var(--n900));
    background: rgb(var(--primary-1));
}
.icon-btn-2 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(var(--primary-1));
    color: rgb(var(--primary-1));
    transition: var(--transition);
}
.icon-btn-2.fill i {
    font-size: 37px;
}
.calture-head h5 {
    font-size: 30px;
    padding: 10px 0px;
}
.caltur {
    border: 10px solid #2f2d2d;
    border-radius: 5px;
}
@media(max-width:575px){
    .calture-head {
        padding-bottom: 30px;
    }
    .calture-head h5 {
        font-size: 22px;
        padding: 0px 0px;
      }
}

@media(max-width:425px){
    .industry-workfor {
        display: block;
        text-align: center;
        padding: 10px 9px;
    }
    .industry-workfor h6 {
        font-size: 14px;
        padding-left: 0px;
    }
    .industry-workfor img {
        width: 35px;
    }
    
}

@media(max-width:375px){
    .calture-head h5 {
        font-size: 20px;
    
      }
      .icon-btn-2.fill i {
        font-size: 32px;
    }
    .caltures.position-relative {
        width: 63%;
    }
}