.texture-bg {
  background: url("../../assets/images/texture-bg.png"), rgb(28, 29, 32);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.input-area {
  display: flex;
  align-self: center;
  padding: 1px 8px 3px 9px;
  border-radius: 32px;
  background: #3b3b3c;
  gap: 85px;
  color: #fff;
  margin-top: 14px;
}
.icon-btn .icon {
  background: rgb(var(--primary-1));
}
button.icon-btn {
  background-color: transparent;
  border: none;
}
.icon-btn .icon.alt-size {
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.footer-logo img {
  width: 100px;
}

.footer-logo a {
  display: block;
  margin-bottom: 15px;
}
.description {
  /* font-size: 15px; */
  font-size: 17px;
  line-height: 150%;
  font-weight: 300;
  /* line-height: 1.7; */
  color: #fff;
}
img.footer-img {
  width: 40px;
  height: 40px;
}
.footer-widget h4 {
  color: #f9f9f9;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
}

.footer-menu {
  list-style: none;
  padding: 0;
}

.footer-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  font-weight: 200;
}
.footer-menu a:hover {
  color: #fcb440;
}
.footer-social li {
  list-style: none;
}
.footer-social a {
  color: #ddd;
  font-size: 18px;
  text-decoration: none;
}
.footer-widget ul li a i {
  font-size: 25px;
  color: #fcb440;
}
.footer-bottom {
  border-top: 1px solid #ffc1073b;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px !important;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.footer-bottom a {
  color: #fff;
  text-decoration: none;
}

.footer-bottom a:hover {
  color: #fff;
}

.footer-widget .gap-4 img {
  width: 35px;
  height: auto;
}

.footer-widget ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  img.footer-img {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 425px) {
  .footer-logo img {
    width: 70px;
  }
  .footer-widget.mt-5 {
    margin-top: 20px !important;
  }}

@media (max-width: 375px) {
  .footer-widget h4 {
    margin-bottom: 10px;
    font-size: 21px;
  }
  ul.footer-menu.d-flex.gap-4 {
    gap: 9px !important;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .footer-menu a {
    font-size: 16px;
  
}
.description {
  font-size: 16px;
}
}